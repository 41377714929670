<template>
  <ion-page hide-nav-bar="true">
    <ion-content>
      <div class="container">
        <div class="content">
          <ion-back-button
            v-if="canGoBack && hasHistory"
            :defaultHref="FEED_PATH"
            mode="md"
            :icon="require('@/assets/images/icons/back.svg')"
          ></ion-back-button>
          <div class="form-wizard-step ion-text-center">
            <div class="form-wizard-step-heading">
              <div class="photo-wrapper">
                <PetAvatar :image="petImg" />
                <div class="user-icon" :class="userPhoto ? '' : 'user-placeholder'">
                  <img :src="userPhoto != null ? userPhoto : userIcon" />
                </div>
              </div>

              <div class="header-text-wrapper">
                <h1 class="header-text">Gazdi fotó</h1>
              </div>
            </div>

            <div class="upload-wrapper">
              <PhotoUpload
                v-model="validations.userImage.$model"
                :error="validations.userImage.$error || hasServerValidationError('avatar')"
                :additionalClass="'user-avatar-upload'"
              />
              <ErrorMessage :field="validations.userImage" />
              <ServerValidation field="avatar" :server-validation="serverValidation" />
              <p class="upload-text">
                <span
                  >Tölts fel gazdifotót magadról. A fotód a kedvenceid profilképe mellett jelenik meg ahogy fent is
                  láthatod.
                </span>
              </p>
            </div>
            <div class="form-wizard-actions">
              <ion-button
                @click.prevent="submit"
                expand="full"
                shape="round"
                :disabled="isLoading"
                strong="true"
                class="btn"
              >
                {{ isLoading ? 'Loading' : 'Mentés' }}
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
              <div class="owner-profile-spacer"></div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed, ref, onBeforeMount, reactive, toRefs } from 'vue'
import { IonButton, IonRippleEffect, IonPage, IonContent } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
//import useRegistration from '@/composables/registration'
import useFormWizard from '@/composables/formWizard'
import useFormControl from '@/composables/formControl'

import PhotoUpload from '@/components/Global/PhotoUpload'
import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'
import PetAvatar from '@/components/Pet/PetAvatar.vue'

import { FEED_ROUTE } from '@/constants/routes'

import usePets from '@/composables/pets'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'UserPhotoEdit',
  components: {
    IonPage,
    IonContent,
    PhotoUpload,
    IonButton,
    IonRippleEffect,
    ErrorMessage,
    ServerValidation,
    PetAvatar,
  },

  setup() {
    // const { setUserPhoto } = useRegistration()
    const { nextStep } = useFormWizard()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const router = useRouter()
    const { PETS_STATE, fetchPet } = usePets()
    const userIcon = computed(() => {
      return require('@/assets/images/icons/profile-user-red-but-actually-gray.svg') //require('@/assets/images/icons/profile-user-gray.svg')
    })
    const userImage = ref(null)
    const isLoading = ref(false)

    const state = reactive({
      petImg: '',
      userPhoto: null,
    })

    const rules = {
      userImage: { required },
    }

    const validations = useVuelidate(rules, {
      userImage,
    })

    const submit = async () => {
      validations.value.$touch()
      // setUserPhoto(userImage)
      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true
        const payload = {
          avatar: userImage.value,
        }
        const {
          data: { data: data },
        } = await httpClient.patch('me', '', payload)

        state.userPhoto = data.avatar
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    onBeforeMount(() => {
      getPet()
    })

    const getPet = async () => {
      try {
        const data = await fetchPet(PETS_STATE.activePetId)

        state.petImg = data.avatar
        state.userPhoto = data.user_avatar
        return Promise.resolve()
      } catch (error) {
        router.push({ name: FEED_ROUTE })
        return Promise.reject(error)
      }
    }

    return {
      pet: getPet,
      submit,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
      userIcon,
      ...toRefs(state),
      nextStep,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-content {
  --background: linear-gradient(#ffffff, #ffffff 50%, #fcdcff);
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.container {
  @media only screen and (max-width: 600px) {
    height: 100%;
  }

  .content {
    @media only screen and (max-width: 600px) {
      height: 100%;
      margin-top: 80px;
      position: relative;
      top: 0;
      //padding-top: 6em;

      .form-wizard-step {
        /*  height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;*/
        top: 0;
        height: 80%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
      }
    }
  }
}

.owner-profile-spacer {
  height: 10px;
  background: transparent;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.back-button {
  cursor: pointer;
}

.photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .pet-avatar {
    border: 5px solid #fff;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 30%);
    position: relative;
    overflow: hidden;
    width: 160px;
    height: 160px;
    min-width: 160px;
    min-height: 160px;
    @media only screen and (max-width: 600px) {
      // position: absolute;
      width: 160px;
      height: 160px;
      // left: 110px;
      // top: 46px;
      background: #ffffff;
      box-shadow: 0px 0px 40px rgb(0 0 0 / 30%);
      border-radius: 10px;
      border-radius: 50%;
      border: 5px solid #fff;
    }
  }

  .user-icon {
    top: 14em;
    position: absolute;
    width: 60px;
    height: 60px;
    left: 51.5%;
    border-radius: 50%;
    animation: zoom-in-zoom-out 1s ease infinite;
    border: 3px solid #fff;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 30%);
    background-color: #fff;
    @media only screen and (max-width: 600px) {
      position: relative;
      /* width: 55px;
      height: 55px;
      left: 50px;
      top: -45px;*/
      width: 60px;
      height: 60px;
      left: 60px;
      top: -60px;
      border: 3px solid #ffffff;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &.user-placeholder {
      border: none;
      width: 50px;
      height: 50px;
    }
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.form-wizard-step-heading {
  @media only screen and (max-width: 600px) {
    /*position: absolute;
    top: 46px;*/
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;
    top: 2em;
  }

  .header-text-wrapper {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 31px;
    text-align: center;
    @media only screen and (max-width: 600px) {
      position: relative;
      top: -3.2em;
      left: 9px;
    }

    .header-text {
      margin-top: 19px;
      letter-spacing: -1px;
      @media only screen and (max-width: 600px) {
        /* position: absolute;
      width: 138px;
      height: 22px;
      left: 119px;
      top: 225px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 800;
      font-size: 21px;
      line-height: 31px;
      text-align: center;
      color: #222222;*/
        width: 138px;
        height: 22px;
        margin-top: 19px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 800;
        font-size: 21px;
        line-height: 31px;
        text-align: center;
        color: #222222;
      }
    }
  }

  ion-img {
    max-width: 90px;
  }
}

.upload-text {
  width: 40%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #222222;
  @media only screen and (max-width: 600px) {
    // width: 285px;
    height: 19px;
    // left: 45px;
    width: 100%;
    // top: 480px;
    margin-bottom: 45px;
    span {
      width: 80%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.validation-errors {
  @media only screen and (max-width: 600px) {
    position: relative;
    top: 250px;
  }
}

.form-wizard-actions {
  margin-top: 25px;
  position: relative;
  bottom: -182px;
  @media only screen and (max-width: 600px) {
    /* width: 100%;
    position: absolute;
    top: 678px;
    bottom: 0;*/
    width: 100%;
    position: relative;
    height: 89px;
    top: 10em;
    bottom: 0;
    ion-button {
      width: 100% !important;
    }
  }

  ion-button {
    margin-top: 31px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
<style lang="scss">
.upload-wrapper {
  position: relative;
  top: 85px;

  @media only screen and (max-width: 600px) {
    /* position: absolute;
    top: 332px;*/
    position: relative;
    top: 12em;
  }

  .user-avatar-upload {
    width: 125px !important;
    height: 125px !important;
    margin: 40px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    position: relative;

    @media only screen and (max-width: 600px) {
      width: 125px !important;
      height: 125px !important;
      margin: 40px auto 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    }

    .upload-icon {
      width: 58.11px;
      height: 56px;
    }
  }
}

.app-header {
  display: block;
}
</style>
